/* eslint camelcase: ["error", {properties: "never"}] */
import Vue from 'vue';
import dayjs from 'dayjs';
// import VueI18n from 'vue-i18n';
// import ElementUI from 'element-ui';
import App from './App.vue';
import router from './router';
import store from './store';

import { i18n, loadLanguageAsync } from './plugins/vue-i18n';

/**
 * Plugins
 * Import plugins here
 */
import './plugins/vue-axios';
import './plugins/vue-logger';
import './plugins/vue-anime';
import './plugins/element-ui';
import './plugins/dayjs';
import './plugins/vue-swiper';
import './plugins/vue-mask';
import './plugins/vue-idle';

/**
 * Main style
 * Import any additional style here
 */
import './assets/scss/styles.scss';

Vue.config.productionTip = false;

let deviceLanguage = '';

// set default language
if (localStorage.getItem('boxLang')) {
  deviceLanguage = localStorage.getItem('boxLang');
} else {
  deviceLanguage = 'de_AT';
}
localStorage.setItem('boxLang', deviceLanguage);

// get url + key and save into localStorage
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);

const apiUrl = urlParams.get('apiUrl');
if (apiUrl) {
  localStorage.setItem('anybox_terminal_api_url', apiUrl);
}
// else {
// localStorage.setItem('anybox_outdoor_api_url', localStorage.getItem('anybox_outdoor_api_url'));
// }

const apiKey = urlParams.get('apiKey');
if (apiKey) {
  localStorage.setItem('anybox_terminal_api_key', apiKey);
}

// init load language
loadLanguageAsync(deviceLanguage).then(() => {
  // error handler
  Vue.config.errorHandler = (err, vm, info) => {
    store.dispatch('setUser').then(async (res) => {
      if (res) {
        const data = {
          userID: res.id,
          appView: vm.$route.path,
          appError: `${err.toString()}`,
          appErrorDestination: info,
          dateTime: dayjs().format('DD.MM.YYYY HH:mm:ss'),
        };

        store.dispatch('setError', {
          appType: 'Terminal',
          deviceType: 'Terminal',
          jsonData: data,
        });
      } else {
        const data = {
          appView: vm.$route.path,
          appError: `${err.toString()}`,
          appErrorDestination: info,
          dateTime: dayjs().format('DD.MM.YYYY HH:mm:ss'),
        };

        store.dispatch('setError', {
          appType: 'Terminal',
          deviceType: 'Terminal',
          jsonData: data,
        });
      }
    });
  };

  new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
  }).$mount('#app');
});
